import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { getRouterSelectors } from '@ngrx/router-store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { distinctUntilChanged, switchMap, takeWhile, tap } from 'rxjs';

import { Store } from '@ngrx/store';

import { PAGE_URL_PATH, TealiumTrackingFacade, URL_PATH } from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { ChapterPageComponent } from '../chapter-page.component';
import { ROUTE_PATHS } from '../../../../util/constant/route-paths';

import { filterDefined } from '../../../../util/filter-defined';

import { ChapterPageComponentStore } from '../../data/component-store/chapter-page.component-store.service';

import { OivViewerFacade } from '../../../core/data/facade/oiv-viewer-facade';

@Component({
    standalone: true,
    selector: 'oiv-viewer-chapter-page-no-topic-component',
    templateUrl: './chapter-page-no-topic-component.component.html',
    styleUrls: ['../chapter-page.component.scss'],
    imports: [CommonModule],
    providers: [ChapterPageComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChapterPageNoTopicComponentComponent extends ChapterPageComponent implements OnInit, AfterViewInit {
    #store = inject(Store);
    #componentStore = inject(ChapterPageComponentStore);
    #oivViewerFacade = inject(OivViewerFacade);
    #tealiumTrackingFacade = inject(TealiumTrackingFacade);
    #destroyRef = inject(DestroyRef);

    #currentUrl = this.#store.selectSignal(getRouterSelectors().selectUrl);
    #latestUrl = '';

    constructor() {
        super();
    }

    ngOnInit(): void {
        /**
         * - This code sets up an observable pipeline to handle URL changes and fetch chapter content based on the chapter ID from the URL.
         * - When navigate to another chapter, it ensures that the observable completes when the component is destroyed.
         * - The `takeWhile` operator is used to avoid rendering multiple times when navigating to other routes by checking if the latest URL is different from the current URL and does not contain 'topics'
         * - The `tap` operator updates the latest URL, and the `switchMap` operators handle fetching the chapter content and setting it in the component store.
         * - This approach prevents multiple render calls when navigating between routes, especially when the router sends both the previous and current URLs.
         */
        this.#store
            .select(getRouterSelectors().selectRouteParam(ROUTE_PATHS.ROUTE_PARAM_CHAPTER_ID))
            .pipe(
                takeUntilDestroyed(this.#destroyRef),
                takeWhile(
                    () =>
                        this.#latestUrl === '' ||
                        (this.#latestUrl !== this.#currentUrl() && !this.#currentUrl().includes(URL_PATH.TOPICS)),
                ),
                tap(() => (this.#latestUrl = this.#currentUrl())),
                filterDefined,
                switchMap(chapterId => {
                    return this.#oivViewerFacade.getChapter(chapterId);
                }),
            )
            .subscribe(content => {
                if (!content) {
                    // Navigate to the homepage if the chapter content is not found
                    this.router.navigate([`../../${PAGE_URL_PATH.HOMEPAGE}`], { relativeTo: this.route });
                } else {
                    this.#componentStore.setSelectedChapter(content);
                }
            });
        this.#render();
        this.scrollFragment();
    }

    #render() {
        this.#componentStore.getSelectedChapter$
            .pipe(takeUntilDestroyed(this.#destroyRef), distinctUntilChanged())
            .subscribe(chapterContent => {
                this.ref.clear();
                this.renderContent(chapterContent, true);
                this.#tealiumTrackingFacade.trackPageView(chapterContent, OIV_TYPE.CHAPTER_TITLE);
            });
    }
}
